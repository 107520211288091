@import "bootstrap/dist/css/bootstrap.css";

body,
input,
select,
textarea {
  font-family: "Montserrat", sans-serif;
}

body,
.btn,
.dropdown-menu {
  font-size: 0.9rem;
}

b,
h1,
h2,
h3,
h4,
h5,
h6,
.font-weight-bold,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  font-weight: 700;
}

hr.dark {
  border-top-color: #ddd;
}

hr.wide {
  margin-bottom: 33px;
  margin-top: 33px;
}

.badge-project {
  border-radius: 0;
  font-size: 60%;
  margin-top: -3px;
  vertical-align: middle;
}

.btn-github {
  background-color: #212529;
  color: #fff;
}

.btn-github:focus,
.btn-github:hover {
  background-color: #444;
  color: #fff;
}

.btn-gitlab {
  background-color: #242556;
  color: #fff;
}

.btn-gitlab:focus,
.btn-gitlab:hover {
  background-color: #464775;
  color: #fff;
}

.btn-nonclick {
  cursor: default;
}

.clickable {
  cursor: pointer;
}

.container-main {
  padding-top: 66px;
}

.container-main + .container-main {
  padding-top: 0;
}

.container-main > .messages .alert:first-child {
  margin-top: 22px;
}

.container-projects .well p:last-child {
  margin-bottom: 0;
}

.fa-icon {
  font-family: "FontAwesome";
}

.footer {
  color: #777;
  font-size: 13px;
}

.footer-main {
  font-size: 14px;
  margin-top: 66px;
}

.footer-main .container .text-muted {
  border-top: 1px solid #eee;
  margin-bottom: 0;
  padding: 10px 0;
}

.indent-bottom {
  margin-bottom: 33px;
}

.indent-bottom-small {
  margin-bottom: 11px;
}

.markdown p:last-child {
  margin-bottom: 0;
}

.nav-item .nav-link.active {
  font-weight: bold;
}

.padding-top-sm {
  padding-top: 10px;
}

.page-header-no-border-bottom {
  border-bottom: 0;
  padding-bottom: 0;
}

.panel-body ul li a.active {
  color: #134fb8;
  font-weight: bold;
  text-decoration: underline;
}

.sub-header {
  border-bottom: 1px solid #e5e5e5;
  padding-bottom: 10px;
}

@media (max-width: 767px) {
  .buttons-xs .btn,
  .form-xs .col-xs-12 {
    margin-bottom: 4px;
  }

  .buttons-xn .btn:last-child {
    margin-bottom: 0;
  }
}
